export const setDefaultDeskId = (id: string, mapUrl: string) => {
    localStorage.setItem('defaultDeskId', id);
    localStorage.setItem('defaultDeskMapUrl', mapUrl);
    localStorage.setItem('defaultDeskMapName', "test");
}

export const getDefaultDeskId = () => {
    return localStorage.getItem('defaultDeskId') || '';
}

export const getDefaultDeskMapUrl = () => {
    return localStorage.getItem('defaultDeskMapUrl') || '';
}

export const getDefaultDeskMapName = () => {
    return localStorage.getItem('defaultDeskMapName') || '';
}

export const setDefaulParkingId = (id: string, mapUrl: string) => {
    localStorage.setItem('defaultParkingId', id);
    localStorage.setItem('defaultParkingMapUrl', mapUrl);
    localStorage.setItem('defaultParkingMapName', "test");
}

export const getDefaultParkingId = () => {
    return localStorage.getItem('defaultParkingId') || '';
}

export const getDefaultParkingMapUrl = () => {
    return localStorage.getItem('defaultParkingMapUrl') || '';
}

export const getDefaultParkingMapName = () => {
    return localStorage.getItem('defaultParkingMapName') || '';
}

export const getMapIdByType = (type: ResourceType) => {
    return type === ResourceType.FLOOR ? getDefaultDeskId() : getDefaultParkingId();
}

export const getMapUrlByType = (type: ResourceType) => {
    return type === ResourceType.FLOOR ? getDefaultDeskMapUrl() : getDefaultParkingMapUrl();
}

export const getMapNameByType = (type: ResourceType) => {
    return type === ResourceType.FLOOR ? getDefaultDeskMapName() : getDefaultParkingMapName();
}

export enum ResourceType {
    FLOOR = "Floor",
    PARKING = "Parking",
}
import { useQuery } from "@tanstack/react-query";
import { getMapsList } from "services/maps/maps.service";

import { MapsList } from "services/maps/maps.types";

export const useMapList = (type: string) => {
  var query = useQuery<MapsList.Response>({
    queryKey: ["MapList", type],
    queryFn: () => {
      return getMapsList({ type });
    },
    enabled: !!type
  });

  return {
    mapsResponse: query.data,
    isMapsLoading: query.isLoading,
    isMapsError: query.isError,
    refechMaps: query.refetch
  };
};

import React, { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { Avatar } from "@mui/material";
import { useProfilePicture } from "hooks/shared/useProfilePicture";
import { getTenantAccount } from "services/authorization/authConfig";

interface UserPhotoProps {
  userId?: string;
  className?: string;
}

const UserProfilePicture: React.FC<UserPhotoProps> = ({
  userId = "",
  className = "",
}) => {
  const { instance, accounts } = useMsal();
  const [accessToken, setAccessToken] = useState<string>("");
  const { data } = useProfilePicture(accessToken, userId);
  const tenantAccount = getTenantAccount(accounts);

  useEffect(() => {
    if(!tenantAccount)
      return;

    const fetchUserPhoto = async () => {
      const request = {
        scopes: ["User.ReadBasic.All"],
        account: tenantAccount,
      };

      try {
        const response = await instance.acquireTokenSilent(request);
        setAccessToken(response.accessToken);
      } catch (error) {}
    };

    fetchUserPhoto();
  }, [instance, accounts, userId]);

  return (
    <Avatar
      className={className}
      src={data}
      sx={{ marginRight: ".8rem", width: "30px", height: "30px" }}
    />
  );
};

export default UserProfilePicture;

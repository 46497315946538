import { useMutation } from "@tanstack/react-query";
import { editSpot } from "../../services/resources/resource.service";
import { EditSpot } from "../../services/resources/resource.types";
import { useAlertStore } from "components/common/alert/useAlertStore";

const useSpotEdit = ({
  onSave,
  onClose,
  setErrors,
}: {
  onSave: (values: any) => void;
  onClose: () => void;
  setErrors: (errors: any) => void;
}) => {
  const alertDispatch = useAlertStore((state) => state.dispatch);

  const { mutate, isPending, error } = useMutation({
    mutationFn: async ({ id, name, color }: EditSpot.Request) =>
      await editSpot({ id, name, color }),
    onSuccess: (data, variables) => {
      onSave(variables);
      onClose();
      alertDispatch({
        type: "ALERT/SET_SUCCESS",
        payload: {
          successMessage: data.message ?? "Spot created successfully!",
        },
      });
    },
    onError: (err: any) => {
      const errors = err.errors || [];
      const formikErrors: any = {};

      if (err.message) {
        alertDispatch({
          type: "ALERT/SET_ERROR",
          payload: {
            errorMessage: err.message,
          },
        });
      }

      errors.forEach((apiError: any) => {
        formikErrors[apiError.field] = apiError.message;
      });

      setErrors(formikErrors);
    },
  });

  return {
    mutateEdit : mutate,
    isPendingEdit: isPending,
    errorEdit: error,
  };
};

export default useSpotEdit;

import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@mui/material";
import { useFormikContext, Formik, Form } from "formik";
import { SelectChangeEvent } from "@mui/material";
import { useMapList } from "hooks/maps/useMapList";
import useMapUpdate from "hooks/maps/useMapUpdate";
import useMapDelete from "hooks/maps/useMapDelete";
import { getErrorText, typeOptions } from "helpers";
import { useFloorStore } from "./useFloorStore";
import theme from 'theme';
import { MapsList } from "services/maps/maps.types";
import { useNavigate } from "react-router-dom";


interface FloorSelectProps {
  onImageChange: (imageUrl: string) => void;
  onFloorIdChange: (floorId: string | null) => void;
}

const FloorSelect: React.FC<FloorSelectProps> = ({
  onImageChange,
  onFloorIdChange,
}) => {
  const { values, errors, touched, setFieldValue, handleChange, handleBlur } =
    useFormikContext<any>();
  const { mapsResponse, isMapsLoading, isMapsError, refechMaps } = useMapList(values.type);

  const currentFloor = useFloorStore((state) => state.floor);

  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [initialFloorName, setInitialFloorName] = useState("");
  const [floorName, setFloorName] = useState("");
  const [isNewFloor, setIsNewFloor] = useState(true);
  const navigate = useNavigate();

  const mapId = values.mapId;

  useEffect(() => {
    const selectedMap = mapsResponse?.maps.find((map) => map.id === mapId);
    const imageUrl = selectedMap?.url || "";
    const selectedFloorId = selectedMap?.id || null;

    if (isNewFloor && currentFloor) {
      setFieldValue("type", currentFloor.type);
      setFieldValue("mapId", currentFloor.id);
      onImageChange(currentFloor.url);
      onFloorIdChange(currentFloor.id);
      setInitialFloorName(currentFloor.name);
      setFloorName(currentFloor.name);
      setIsNewFloor(false);
    } else {
      onImageChange(imageUrl);
      onFloorIdChange(selectedFloorId);
      setInitialFloorName(selectedMap?.name || "");
      setFloorName(selectedMap?.name || "");
    }
  }, [
    mapId,
    mapsResponse?.maps,
    currentFloor,
    onImageChange,
    onFloorIdChange,
    setFieldValue,
    isNewFloor,
  ]);


  const handleTypeChange = (event: SelectChangeEvent<string>) => {
    const selectedType = event.target.value as string;
    setFieldValue("type", selectedType);
    setFieldValue("mapId", "");
    onFloorIdChange(null);
  };

  const handlePrintQR = () => {
    navigate(`/settings/qr-codes/map/${mapId}`);
  }

  const openEditDialog = () => setIsEditDialogOpen(true);
  const closeEditDialog = () => {
    setIsEditDialogOpen(false);
    setFloorName(initialFloorName);
  };

  const { mutate: updateMap, isPending: isUpdating } = useMapUpdate({
    onSave: () => {
      refechMaps();
      closeEditDialog();
    },
    setErrors: () => { },
  });

  const { mutate: deleteMap, isPending: isDeleting } = useMapDelete({
    onDelete: () => {
      setFieldValue("mapId", "");
      refechMaps();
      closeEditDialog();
    },
    setErrors: () => { },
  });

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        alignItems: { xs: "normal", md: "flex-start" },
        gap: 2,
        flexDirection: {
          xs: "column",
          md: "row",
        },
      }}
    >
      <FormControl fullWidth error={Boolean(touched.type && errors.type)} size="small">
        <Select
          id="type-select"
          name="type"
          value={values.type}
          onChange={handleTypeChange}
          onBlur={handleBlur}
          displayEmpty
        >
          <MenuItem value="" disabled
          >
            Select Type
          </MenuItem>
          {typeOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
        {touched.type && errors.type && (
          <FormHelperText sx={{ color: "red" }}>
            {getErrorText(errors.type)}
          </FormHelperText>
        )}
      </FormControl>

      <FormControl
        fullWidth
        error={Boolean(touched.mapId && errors.mapId)}
        disabled={!values.type}
        size="small"
      >
        <Select
          id="map-select"
          name="mapId"
          value={mapId || ""}
          onChange={(event: SelectChangeEvent<string>) => handleChange(event)}
          onBlur={handleBlur}
          displayEmpty
        >
          <MenuItem value="" disabled>
            Select Map
          </MenuItem>
          {isMapsLoading && (
            <MenuItem disabled>
              <CircularProgress size={24} />
            </MenuItem>
          )}
          {isMapsError && <MenuItem disabled>Error loading maps</MenuItem>}
          {mapsResponse?.maps.map((map: MapsList.Item) => (
            <MenuItem key={map.id} value={map.id}>
              {map.name}
            </MenuItem>
          ))}
        </Select>
        {touched.mapId && errors.mapId && (
          <FormHelperText sx={{ color: "red" }}>
            {getErrorText(errors.mapId)}
          </FormHelperText>
        )}
      </FormControl>

      <Button
        variant="contained"
        onClick={openEditDialog}
        disabled={!mapId}
        sx={{
          color: theme.palette.neutral.contrastText,
          fontWeight: "bold",
          minWidth: "120px",
        }}
      >
        Edit Floor
      </Button>
      <Button
        variant="contained"
        onClick={handlePrintQR}
        disabled={!mapId}
        sx={{
          color: theme.palette.neutral.contrastText,
          fontWeight: "bold",
          minWidth: "120px",
        }}
      >
        Print QR
      </Button>
      <Dialog open={isEditDialogOpen} onClose={closeEditDialog}>
        <Formik
          initialValues={{ name: floorName }}
          enableReinitialize
          onSubmit={(formValues, { setErrors }) => {
            if (mapId && formValues.name !== initialFloorName) {
              updateMap(
                { id: mapId, name: formValues.name },
                {
                  onError: (err: any) => {
                    const errors = err.errors || [];
                    const formikErrors: any = {};
                    errors.forEach((apiError: any) => {
                      formikErrors[apiError.field] = apiError.message;
                    });
                    setErrors(formikErrors);
                  },
                },
              );
            }
          }}
        >
          {({ values, handleChange, errors, touched }) => (
            <Form>
              <DialogTitle>Edit Floor</DialogTitle>
              <DialogContent>
                <TextField
                  label="Floor Name"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    if (mapId) {
                      deleteMap({ id: mapId });
                    }
                  }}
                  color="error"
                  disabled={isDeleting}
                >
                  {isDeleting ? "Deleting..." : "Delete"}
                </Button>
                <Button onClick={closeEditDialog}>Cancel</Button>
                <Button type="submit" color="primary" disabled={isUpdating}>
                  {isUpdating ? "Saving..." : "Save"}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </Box>
  );
};

export default FloorSelect;

import React, { useState } from "react";
import ImageMapper from "react-img-mapper";
import { useReservationMapOverview } from "../../../hooks/reservation/useReservationMapOverview";
import {
  Box,
  CircularProgress,
} from "@mui/material";
import styles from "./SpotsMapper.module.css";
import CreateReservationDialog, {
  ReservationDetails,
} from "../createReservationDialog";
import { useMediaQuery } from "@mui/material";
import useReservationCancel from "hooks/reservation/useReservationCancel";
import ConfirmationDialog from "components/common/dialog/confirmation";
import { CONFIRMATION_TEXTS } from "helpers";
import CreateSpotDialog from "../createSpotDialog";
import useResourceDelete from "hooks/resources/useResourceDelete";
import { ResourceType } from "services/resources/defaultMap";
import { MapsList } from "services/maps/maps.types";
import { Dayjs } from "dayjs";
import MapSelect from "components/common/mapSelect";

interface SpotsMapperProps {
  type: ResourceType;
  width?: number;
  imgWidth?: number;
  enableEditing?: boolean;
  selectedDate: Dayjs;
}

const SpotsMapper: React.FC<SpotsMapperProps> = ({
  type,
  width = 700,
  imgWidth = 800,
  enableEditing = false,
  selectedDate,
}) => {
  const isSmallScreen = useMediaQuery("(max-width:768px)");
  const isMediumScreen = useMediaQuery("(max-width:990px)");
  width = isSmallScreen ? 400 : isMediumScreen ? 600 : 800;

  const [tooltip, setTooltip] = useState({
    visible: false,
    x: 0,
    y: 0,
    text: [],
  });
  const [selectedDesk, setSelectedDesk] = useState<ReservationDetails>({
    date: selectedDate.format("YYYY-MM-DD"),
    resourceId: null,
    timeslot: null
  });
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [selectedReservationId, setSelectedReservationId] = useState<
    string | null
  >(null);
  const [selectedSpotId, setSelectedSpotId] = useState<string | null>(null);

  const [selectedMap, setSelectedMap] = useState<MapsList.Item | null>(null);

  const { data, isError, isLoading, refetch } = useReservationMapOverview(
    selectedMap?.id || "",
    selectedDate.format("YYYY-MM-DD"),
  );

  const { cancelReservation, cancelReservationLoading } = useReservationCancel({
    mapId: selectedMap?.id || "",
    date: selectedDate.format("YYYY-MM-DD"),
    onSucess: handleCloseConfirmDialog,
  });

  const { deleteSpot, deleteSpotLoading } = useResourceDelete({
    onSucess: () => {
      handleCloseConfirmDialog();
      refetch();
    },
  });

  const [createReservationModalOpen, setCreateReservationModalOpen] =
    useState(false);
  const [isExistingSpot, setIsExistingSpot] = useState(false);
  const [editResourceModalOpen, setEditResourceModalOpen] = useState(false);
  const [editResourceData, setEditResourceData] = useState({
    color: "#00ff00",
    id: "",
    name: "",
  });

  const [newSpotModalOpen, setNewSpotModalOpen] = useState(false);
  const [newSpotData, setNewSpotData] = useState({
    color: "#00ff00",
    mapId: selectedMap?.id || "",
    name: "",
    x: 0,
    y: 0,
  });

  const areas =
    data?.resources?.map((desk) => ({
      id: desk.id,
      shape: "circle",
      coords: [desk.x, desk.y, 10],
      preFillColor: desk.reservation
        ? "rgba(255, 0, 0, 0.5)"
        : "rgba(0, 255, 0, 0.5)",
      fillColor: desk.reservation
        ? "rgba(255, 0, 0, 0.5)"
        : "rgba(0, 255, 0, 0.5)",
      description: [
        `${desk.name}`,
        `Reserved: ${desk.reservation ? "Yes" : "No"}`,
        `${desk.reservation ? `${desk.reservation.reservedBy}` : ""}`,
      ],
    })) || [];

  // Handlers.
  const handleMouseEnter = (area: any, event: any) => {
    setTooltip({
      visible: true,
      x: event.clientX,
      y: event.clientY,
      text: area.description,
    });
  };

  const handleMouseMove = (event: any) => {
    setTooltip((prev) => ({
      ...prev,
      x: event.clientX,
      y: event.clientY,
    }));
  };

  const handleMouseLeave = () => {
    setTooltip({ visible: false, x: 0, y: 0, text: [] });
  };

  const handleAreaClick = (area: any) => {
    const clickedSpot = data?.resources?.find((desk) => desk.id === area.id);

    if (!clickedSpot) return;
    setIsExistingSpot(true);

    if (enableEditing) {
      setEditResourceData({
        color: clickedSpot.color,
        id: area.id,
        name: clickedSpot.name,
      });
      setSelectedSpotId(area.id);
      setEditResourceModalOpen(true);
      return;
    }

    if (clickedSpot.reservation == null) {
      setSelectedDesk({
        resourceId: clickedSpot.id,
        date: selectedDate.format("YYYY-MM-DD"),
        timeslot: null
      });
      setCreateReservationModalOpen(true);
    } else if (clickedSpot.reservation.canCancel) {
      setSelectedReservationId(clickedSpot.reservation.reservationId);
      setOpenConfirmDialog(true);
    }
  };
  const handleConfirmationDialogOpen = () => {
    setOpenConfirmDialog(true);
    setEditResourceModalOpen(false);
  };

  const handleImageClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    if (!enableEditing) return;
    const { nativeEvent } = event;
    const { offsetX, offsetY } = nativeEvent;

    setNewSpotData({
      ...newSpotData,
      mapId: selectedMap?.id || "",
      x: offsetX,
      y: offsetY,
    });
    setNewSpotModalOpen(true);
  };

  const handleCloseModal = () => {
    setCreateReservationModalOpen(false);
    setSelectedDesk({ date: null, resourceId: null, timeslot: null });
  };

  const handleSaveReservation = () => {
    handleCloseModal();
    refetch();
  };

  function handleCloseConfirmDialog() {
    setOpenConfirmDialog(false);
    setSelectedReservationId(null);
    setSelectedSpotId(null);
  }

  const handleConfirmCancellation = () => {
    if (selectedReservationId) {
      cancelReservation({ id: selectedReservationId });
    }
  };

  const handleConfirmDeletion = () => {
    if (selectedSpotId) {
      deleteSpot({ id: selectedSpotId });
    }
  };

  const handleSaveNewSpot = () => {
    setNewSpotModalOpen(false);
    setNewSpotData({
      mapId: selectedMap?.id || "",
      name: "",
      x: 0,
      y: 0,
      color: "#00ff00",
    });
    refetch();
  };

  const handleSaveExistingSpot = () => {
    setNewSpotModalOpen(false);
    setEditResourceData({ color: "#00ff00", id: "", name: "" });
    refetch();
  };


  return (
    <>
      <Box
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <MapSelect type={type} selectedMap={selectedMap} setSelectedMap={setSelectedMap} />

        {isLoading && <CircularProgress />}
        {isError && <div>Error loading desks. Please try again.</div>}
        {selectedMap && (
          <>
            {tooltip.visible && (
              <Box
                className={styles.tooltip}
                style={{
                  top: tooltip.y + 10,
                  left: tooltip.x + 10,
                }}
              >
                {tooltip.text.map((line, index) => (
                  <Box key={index}>{line}</Box>
                ))}
              </Box>
            )}

            <ImageMapper
              src={selectedMap.url}
              map={{ name: "spots-map", areas: areas }}
              width={width}
              imgWidth={imgWidth}
              onMouseEnter={(area, _, event) => handleMouseEnter(area, event)}
              onMouseMove={(area, _, event) => handleMouseMove(event)}
              onMouseLeave={handleMouseLeave}
              onClick={handleAreaClick}
              onImageClick={enableEditing ? handleImageClick : undefined}
            />
          </>
        )}
      </Box>

      {createReservationModalOpen && (
        <CreateReservationDialog
          open={createReservationModalOpen}
          onClose={handleCloseModal}
          onSave={handleSaveReservation}
          initialDetails={selectedDesk}
          mapId={selectedMap?.id || ""}
        />
      )}

      <ConfirmationDialog
        open={openConfirmDialog}
        onClose={handleCloseConfirmDialog}
        onConfirm={
          enableEditing ? handleConfirmDeletion : handleConfirmCancellation
        }
        title={
          enableEditing
            ? CONFIRMATION_TEXTS.deleteSpot.title
            : CONFIRMATION_TEXTS.cancelReservation.title
        }
        contentText={
          enableEditing
            ? CONFIRMATION_TEXTS.deleteSpot.contentText
            : CONFIRMATION_TEXTS.cancelReservation.contentText
        }
        isLoading={
          enableEditing ? deleteSpotLoading : cancelReservationLoading
        }
      />

      {enableEditing && (
        <>
          <CreateSpotDialog
            open={editResourceModalOpen || newSpotModalOpen}
            spotData={isExistingSpot ? editResourceData : newSpotData}
            isExistingSpot={isExistingSpot}
            onClose={() => {
              setEditResourceModalOpen(false);
              setNewSpotModalOpen(false);

              //Reset data if modal is closed.
              setEditResourceData({
                color: "#00ff00",
                id: "",
                name: "",
              });
              setIsExistingSpot(false);
            }}
            onSave={isExistingSpot ? handleSaveExistingSpot : handleSaveNewSpot}
            onDelete={handleConfirmationDialogOpen}
          />


        </>
      )}
    </>
  );
};

export default SpotsMapper;

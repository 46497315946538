import React, { useEffect } from "react";
import {
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import { useMapList } from "hooks/maps/useMapList";
import { getMapIdByType, ResourceType } from "services/resources/defaultMap";
import { MapsList } from "services/maps/maps.types";
import { LoadingSpinner } from "../loadingSpinner";

interface MapSelectProps {
  type: ResourceType;
  selectedMap: MapsList.Item | null;
  setSelectedMap: React.Dispatch<React.SetStateAction<MapsList.Item | null>>;
}

const MapSelect: React.FC<MapSelectProps> = ({
  type,
  selectedMap,
  setSelectedMap
}) => {

  const { mapsResponse, isMapsLoading } = useMapList(type);

  const handleMapChange = (event: SelectChangeEvent<string>) => {
    const selected = mapsResponse?.maps.find(
      (map) => map.id === event.target.value,
    );
    if (selected) {
      setSelectedMap(selected);
    }
  };

  useEffect(() => {
    selectDefaultMap();
  }, [mapsResponse]);

  const selectDefaultMap = () => {
    var defaultMapId = getMapIdByType(type);
    let defaultMap = mapsResponse?.maps?.[0] || null;

    if (defaultMapId) {
      defaultMap =
        mapsResponse?.maps.find((map) => map.id === defaultMapId) || null;
    }

    if (defaultMap)
      setSelectedMap(defaultMap);
  };

  if (isMapsLoading)
    return <LoadingSpinner />

  return (
    <FormControl sx={{ m: 1, maxWidth: 180, minWidth: 150 }} size="small">
      <Select
        value={selectedMap?.id || ''}
        onChange={handleMapChange}
        displayEmpty
        sx={{ mb: 3 }}
      >
        <MenuItem value="" disabled>
          {`Floor`}
        </MenuItem>
        {mapsResponse?.maps.map((map) => (
          <MenuItem key={map.id} value={map.id}>
            {map.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default MapSelect;

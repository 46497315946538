import { useMutation } from "@tanstack/react-query";
import { cancelReservation } from "../../services/reservation/reservation.service";
import { useAlertStore } from "components/common/alert/useAlertStore";
import { queryClient } from "index";
import {
  ReservationTableOverview,
  ReservationMapOverview,
  ReservationPeopleOverview,
} from "services/reservation/reservation.types";
import { updateMapReservationById, updatePeopleReservationById, updateTableReservationById } from "helpers";

const useReservationCancel = ({
  mapId,
  includeWeekends,
  date,
  page,
  pageSize,
  onSucess,
}: {
  mapId: string;
  includeWeekends?: boolean;
  date?: string;
  page?:number,
  pageSize?: number,
  onSucess?: () => void;
}) => {
  const alertDispatch = useAlertStore((state) => state.dispatch);

  const { mutate, isPending, error } = useMutation({
    mutationFn: async ({ id }: { id: string }) =>
      await cancelReservation({ id }),
    onMutate({ id }) {
      const previousTableData = queryClient.getQueryData([
        "reservationTableOverview",
        mapId,
        includeWeekends,
        date,
        page,
        pageSize
      ]);

      const previousMapData = queryClient.getQueryData([
        "reservationMapOverview",
        mapId,
      ]);
      const previousPeopleData = queryClient.getQueryData([
        "reservationPeopleOverview",
        mapId,
        includeWeekends,
        date,
      ]);

      queryClient.setQueryData(
        ["reservationTableOverview", mapId, includeWeekends, date, page, pageSize],
        (reservations: ReservationTableOverview.Response) => {
          if (reservations) {
            return {
              ...reservations,
              resources: updateTableReservationById(id, reservations.resources),
            };
          }
        },
      );
      queryClient.setQueryData(
        ["reservationMapOverview", mapId, date],
        (data: ReservationMapOverview.Response) => {
          if (data) {
            return {
              ...data,
              resources: updateMapReservationById(id, data.resources),
            };
          }
        },
      );
      queryClient.setQueryData(
        ["reservationPeopleOverview", mapId, includeWeekends, date, page, pageSize],
        (reservation: ReservationPeopleOverview.Response) => {
          if (reservation) {
            return {
              ...reservation,
              users: updatePeopleReservationById(id, reservation.users),
            };
          }
        },
      );
      return { previousTableData, previousMapData, previousPeopleData };
    },

    onSuccess: (data) => {
      alertDispatch({
        type: "ALERT/SET_SUCCESS",
        payload: {
          successMessage: data.message ?? "",
        },
      });
      onSucess?.();
    },
    onError: (error, _, context) => {
      if (context?.previousTableData) {
        queryClient.setQueryData(
          ["reservationTableOverview", mapId, includeWeekends, date],
          context.previousTableData,
        );
      }
      if (context?.previousMapData) {
        queryClient.setQueryData(
          ["reservationMapOverview", mapId],
          context.previousMapData,
        );
      }
      if (context?.previousPeopleData) {
        queryClient.setQueryData(
          ["reservationPeopleOverview", mapId],
          context.previousMapData,
        );
      }
      alertDispatch({
        type: "ALERT/SET_ERROR",
        payload: {
          errorMessage: error.message ?? "",
        },
      });
    },
  });

  return {
    cancelReservation: mutate,
    cancelReservationLoading: isPending,
    cancelReservationError: error,
  };
};

export default useReservationCancel;

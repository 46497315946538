import { post } from "../httpHelper";
import { CreateCheckInOut } from "./check-in-out.types";

export async function createCheckInOut({
  type,
  mapId
}: CreateCheckInOut.Request): Promise<CreateCheckInOut.Response> {
  const url = `CheckInOut/create`;
  const payload = {
    type,
    mapId,
  };
  return await post(url, payload);
}
import { useMutation } from '@tanstack/react-query';
import { useAlertStore } from "components/common/alert/useAlertStore";
import { timeslotsUpdate } from 'services/timeslots/timeslots.service';
import { TimeslotsUpdate } from 'services/timeslots/timeslots.types';


const useTimeslotsUpdate = ({
  onSave,
}: {
  onSave: (values: any) => void;
}) => {
  const alertDispatch = useAlertStore((state) => state.dispatch);

  const { mutate, isPending, error } = useMutation({
    mutationFn: async ({id, name, from, to, isDefault }: TimeslotsUpdate.Request) =>
      await timeslotsUpdate({ id, name, from, to, isDefault }),
    onSuccess: (data) => {
      onSave(data);
      alertDispatch({
        type: "ALERT/SET_SUCCESS",
        payload: {
          successMessage: data.message ?? "",
        },
      });
    },
    onError: (err: any) => {
      if (err.message) {
        alertDispatch({
          type: "ALERT/SET_ERROR",
          payload: {
            errorMessage: err.message,
          },
        });
      }
    },
  });

  return {
    mutate,
    isPending,
    error,
  };
};

export default useTimeslotsUpdate;
import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LoadingButton } from "@mui/lab";
import { Formik, Form } from "formik";
import useReservationCreate from "../../../hooks/reservation/useReservationCreate";
import InputDatePicker from "components/common/datePicker";
import ResourceSelect from "components/common/resourceSelect";
import TimeSlotAvailableSelect from "components/common/timeSlotAvailableSelect";

interface CreateReservationDialogProps {
  open: boolean;
  onClose: () => void;
  onSave: (details: ReservationDetails) => void;
  initialDetails: ReservationDetails;
  mapId: string;
}

export interface ReservationDetails {
  date: string | null;
  resourceId: string | null;
  timeslot: string | null
}

const CreateReservationDialog: React.FC<CreateReservationDialogProps> = ({
  open,
  onClose,
  onSave,
  initialDetails,
  mapId,
}) => {
  const { mutate, isPending } = useReservationCreate({
    onSave,
    onClose,
    setErrors: () => { },
  });

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Dialog open={open} onClose={onClose}>
        <Formik
          initialValues={initialDetails}
          enableReinitialize
          onSubmit={(values, { setErrors }) => {
            mutate(
              { resourceId: values.resourceId, date: values.date, timeslot: values.timeslot },
              {
                onError: (err) => {
                  setErrors(err.errors || {});
                },
              }
            );
          }}
        >
          {({ handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              <DialogTitle>New Reservation</DialogTitle>
              <DialogContent>
                <InputDatePicker name="date" />
                <ResourceSelect mapId={mapId} />
                <TimeSlotAvailableSelect />
              </DialogContent>

              <DialogActions>
                <Button onClick={onClose} color="primary">
                  Cancel
                </Button>

                <LoadingButton
                  type="submit"
                  variant="contained"
                  color="secondary"
                  loading={isPending}
                  disabled={isPending}
                >
                  Save
                </LoadingButton>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </LocalizationProvider>
  );
};

export default CreateReservationDialog;

import { useMutation } from "@tanstack/react-query";
import { createSpot } from "../../services/resources/resource.service";
import { CreateSpot } from "../../services/resources/resource.types";
import { useAlertStore } from "components/common/alert/useAlertStore";
import { Pending } from "@mui/icons-material";

const useSpotCreate = ({
  onSave,
  onClose,
  setErrors,
}: {
  onSave: (values: any) => void;
  onClose: () => void;
  setErrors: (errors: any) => void;
}) => {
  const alertDispatch = useAlertStore((state) => state.dispatch);

  const { mutate, isPending, error } = useMutation({
    mutationFn: async ({ mapId, name, x, y, color }: CreateSpot.Request) =>
      await createSpot({ mapId, name, x, y, color }),
    onSuccess: (data, variables) => {
      onSave(variables);
      onClose();
      alertDispatch({
        type: "ALERT/SET_SUCCESS",
        payload: {
          successMessage: data.message ?? "Spot created successfully!",
        },
      });
    },
    onError: (err: any) => {
      const errors = err.errors || [];
      const formikErrors: any = {};

      if (err.message) {
        alertDispatch({
          type: "ALERT/SET_ERROR",
          payload: {
            errorMessage: err.message,
          },
        });
      }

      errors.forEach((apiError: any) => {
        formikErrors[apiError.field] = apiError.message;
      });

      setErrors(formikErrors);
    },
  });

  return {
    mutateCreate: mutate,
    isPendingCreate : isPending,
    errorCreate: error,
  };
};

export default useSpotCreate;

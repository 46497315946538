import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Typography,
  Switch,
  FormControlLabel,
} from '@mui/material';
import { useTimeslotsOverview } from 'hooks/timeslots/useTimeslotsOverview';
import { usePagination } from 'helpers/hooks/usePagination';
import useRequireUseOfTimeslots from 'hooks/timeslots/useRequireUseOfTimeslots';
import TimeslotsTable from './timeslotsTable';
import NewTimeslotDialog from './timeslotsDialog';
import theme from 'theme';

const Timeslots: React.FC = () => {
  const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } =
    usePagination({
      initialPage: 1,
      initialRowsPerPage: 10,
    });

  const { data, isLoading, isError, refetch } = useTimeslotsOverview(
    page + 1,
    rowsPerPage,
  );
  const totalCount = data?.total || 0;

  const [isDialogOpen, setDialogOpen] = useState(false);
  const [selectedTimeslot, setSelectedTimeslot] = useState<any | null>(null);
  const [isTimeslotsActive, setTimeslotsActive] = useState(false);

  const { mutate: toggleTimeslots, isPending } = useRequireUseOfTimeslots();

  useEffect(() => {
    if (data) {
      setTimeslotsActive(data.requireUseOPredefinedTimeslots);
    }
  }, [data]);

  const handleToggle = () => {
    const newStatus = !isTimeslotsActive;
    setTimeslotsActive(newStatus);
    toggleTimeslots(newStatus);
  };

  const handleOpenDialog = () => {
    setSelectedTimeslot(null);
    setDialogOpen(true);
  };

  const handleRowClick = (timeslot: any) => {
    setSelectedTimeslot(timeslot);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleSaveTimeslot = () => {
    refetch();
    handleCloseDialog();
  };

  return (
    <Box>
      {isLoading && (
        <Box display='flex' justifyContent='center' alignItems='center'>
          <CircularProgress />
        </Box>
      )}
      {isError && <Box>Error loading timeslots.</Box>}
      {!isLoading && data && (
        <Box>
          <Box sx={{ paddingLeft: 0, marginTop: 3, marginBottom: 3 }}>
            <Typography variant='h5' gutterBottom>
              Timeslots
            </Typography>
            <Typography
              variant='body1'
              gutterBottom
              display='flex'
              alignItems='center'
            >
              Configure most used timeslots for your company.
            </Typography>
          </Box>
          <Box
            display={{ sm: 'block', md: 'flex' }}
            alignItems='center'
            mb={2}
            justifyContent='space-between'
          >
            <FormControlLabel
              control={
                <Switch
                  checked={isTimeslotsActive}
                  onChange={handleToggle}
                  disabled={isPending}
                />
              }
              label='Require use of pre-defined timeslots'
            />
            <Button
              variant='contained'
              onClick={handleOpenDialog}
              sx={{
                color: theme.palette.neutral.contrastText,
                fontWeight: 'bold',
                minWidth: { xs: '100%', md: '120px' },
              }}
            >
              New Timeslot
            </Button>
          </Box>
          <TimeslotsTable
            data={data.timeslots}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            totalCount={totalCount}
            onRowClick={handleRowClick}
          />
          <NewTimeslotDialog
            open={isDialogOpen}
            onClose={handleCloseDialog}
            onSave={handleSaveTimeslot}
            timeslotData={selectedTimeslot}
          />
        </Box>
      )}
    </Box>
  );
};

export default Timeslots;

import React, { useEffect, useState } from 'react';
import { Box, Tab, } from '@mui/material';
import SpotsMapper from '../mapper';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import ReservationTable from '../resourceReservationTable';
import { getMapIdByType, getMapUrlByType, getMapNameByType, ResourceType } from 'services/resources/defaultMap';
import Calendar from 'components/common/calendar';
import dayjs, { Dayjs } from 'dayjs';

interface ResourcePageProps {
  type: ResourceType;
  defaultTab: DefaultTab;
}

export enum DefaultTab {
  MAP = "MAP",
  TABLE = "TABLE",
}

const ResourcePage: React.FC<ResourcePageProps> = ({
  type,
  defaultTab
}) => {
  const [value, setValue] = React.useState('1');
  const [mapId, setMapId] = React.useState('');
  const [selectedDate, setSelectedDate] = useState<Dayjs>(dayjs());

  useEffect(() => {
    setMapId(getMapIdByType(type));
    setValue(defaultTab === DefaultTab.MAP ? '1' : '2');
  }, [defaultTab]);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    mapId && <TabContext value={value}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <TabList onChange={handleChange}>
          <Tab label="Map" value="1" />
          <Tab label="Table" value="2" />
        </TabList>
      </Box>
      <TabPanel value="1" sx={{ padding: "0" }}>
        <Box>
          <Calendar
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
          />
          <SpotsMapper type={type} selectedDate={selectedDate} />
        </Box>
      </TabPanel>
      <TabPanel value="2" sx={{ padding: "2rem 0" }}>
        <ReservationTable type={type} />
      </TabPanel>
    </TabContext>
  );
};

export default ResourcePage;

import { Box, IconButton, Typography } from "@mui/material";
import { DateCalendar, LocalizationProvider } from "@mui/x-date-pickers";
import { Dayjs } from "dayjs";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import React, { useEffect, useState } from "react";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { endOfWeek, format, startOfWeek } from "date-fns";
import theme from "../../../theme";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

interface WeekCalendarProps {
  selectedDate: Dayjs;
  setSelectedDate: (date: Dayjs) => void;
}

const WeekCalendar: React.FC<WeekCalendarProps> = ({
  selectedDate,
  setSelectedDate,
}) => {
  const [weekRange, setWeekRange] = useState<string>("");
  const [openCalendar, setOpenCalendar] = useState(false);

  const calculateWeekRange = (date: Dayjs) => {
    const start = startOfWeek(date.toDate(), { weekStartsOn: 1 });
    const end = endOfWeek(date.toDate(), { weekStartsOn: 1 });
    const formattedStart = format(start, "EEEE, MMMM do");
    const formattedEnd = format(end, "EEEE, MMMM do");
    setWeekRange(`${formattedStart} - ${formattedEnd}`);
  };

  useEffect(() => {
    calculateWeekRange(selectedDate);
  }, [selectedDate]);

  const handleDateSelect = (date: Dayjs | null) => {
    if (date) {
      setSelectedDate(date);
    }
    setOpenCalendar(false);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box
        sx={{
          position: "relative",
          display: "flex",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            flexGrow: 1,
            justifyContent: "flex-end",
          }}
        >
          <IconButton
            onClick={() => setSelectedDate(selectedDate.subtract(1, "week"))}
          >
            <ArrowBackIosIcon sx={{ display: { xs: "none", md: "block" } }}/>
          </IconButton>

          <Typography
            gutterBottom
            sx={{
              color: theme.palette.secondary.main,
              verticalAlign: "middle",
              marginBottom: "0",
              fontSize: {
                xs: ".85rem",
                sm: "1rem",
              },
              display: {
                xs: "none",
                sm: "block"
              }
            }}
          >
            {weekRange}
          </Typography>

          <IconButton sx={{ display: { xs: "none", md: "block" } }}
            onClick={() => setSelectedDate(selectedDate.add(1, "week"))}
          >
            <ArrowForwardIosIcon fontSize="small" />
          </IconButton>

          <IconButton onClick={() => setOpenCalendar(!openCalendar)}>
            <CalendarTodayIcon sx={{ color: theme.palette.primary.main }} />
          </IconButton>
        </Box>

        {openCalendar && (
          <Box
            sx={{
              position: "absolute",
              top: "40px",
              right: 0,
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
              zIndex: 1,
              bgcolor: theme.palette.neutral.contrastText,
            }}
          >
            <DateCalendar value={selectedDate} onChange={handleDateSelect} />
          </Box>
        )}
      </Box>
    </LocalizationProvider>
  );
};

export default WeekCalendar;

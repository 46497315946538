import { useMutation } from '@tanstack/react-query';
import { uploadMapImage } from '../../services/maps/maps.service';
import { MapImageUpload } from '../../services/maps/maps.types';
import { useAlertStore } from "components/common/alert/useAlertStore";

const useMapImageUpload = ({
  onUploadSuccess,
}: {
  onUploadSuccess: (values: any) => void;
}) => {
  const alertDispatch = useAlertStore((state) => state.dispatch);

  const { mutate, isPending: isUploading, error } = useMutation({
    mutationFn: async ({ id, file }: MapImageUpload.Request) =>
      await uploadMapImage({ id, file }),
      
    onSuccess: (data, variables) => {
      onUploadSuccess(variables);
      alertDispatch({
        type: "ALERT/SET_SUCCESS",
        payload: {
          successMessage: data.message ?? "",
        },
      });
    },

    onError: (err: any) => {
      alertDispatch({
        type: "ALERT/SET_ERROR",
        payload: {
          errorMessage: err.message ?? "",
        },
      });
    },
  });

  return {
    mutate,
    isUploading,
    error,
  };
};

export default useMapImageUpload;

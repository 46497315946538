import { useState, useEffect } from "react";
import {
  Box,
  Stack,
  Button,
  FormControlLabel,
  Switch,
  CircularProgress,
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import { useReservationTableOverview } from "../../../hooks/reservation/useReservationTableOverview";
import useReservationCancel from "hooks/reservation/useReservationCancel";
import CreateReservationDialog, { ReservationDetails } from "../createReservationDialog";
import { CONFIRMATION_TEXTS } from "helpers";
import ConfirmationDialog from "components/common/dialog/confirmation";
import dayjs, { Dayjs } from "dayjs";
import WeekCalendar from "components/common/weekCalendar";
import theme from "theme";
import ResourceTable from "./resourceTable";
import { usePagination } from "helpers/hooks/usePagination";
import { useMapList } from "hooks/maps/useMapList";
import { getMapIdByType, ResourceType } from "services/resources/defaultMap";
import { MapsList } from "services/maps/maps.types";
import MapSelect from "components/common/mapSelect";

interface ParkingOverviewProps {
  type: ResourceType;
}

const ResourceReservationTable = ({ type }: ParkingOverviewProps) => {
  const [selectedDate, setSelectedDate] = useState<Dayjs>(dayjs());
  const [includeWeekends, setIncludeWeekends] = useState(false);
  const [createReservationModalOpen, setCreateReservationModalOpen] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [reservationDetails, setReservationDetails] = useState<ReservationDetails>({
    date: null,
    resourceId: null,
    timeslot: null
  });
  const [selectedReservationId, setSelectedReservationId] = useState<string | null>(null);

  const {
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
    resetPage,
  } = usePagination({
    initialPage: 1,
    initialRowsPerPage: 10,
  });


  const [selectedMap, setSelectedMap] = useState<MapsList.Item | null>(null);

  const { data, isError, isLoading, refetch } = useReservationTableOverview(
    selectedMap?.id ?? "",
    includeWeekends,
    selectedDate.format("YYYY-MM-DD"),
    page + 1,
    rowsPerPage
  );

  const { cancelReservation, cancelReservationLoading } = useReservationCancel({
    mapId: selectedMap?.id ?? "",
    includeWeekends,
    date: selectedDate.format("YYYY-MM-DD"),
    page: page + 1,
    pageSize: rowsPerPage,
    onSucess: handleCloseConfirmDialog,
  });

  const handleCreateReservation = (date: string | null = null, resourceId: string | null = null, timeslot: string | null = null) => {
    setReservationDetails({
      date: date,
      resourceId: resourceId,
      timeslot: timeslot
    });
    setCreateReservationModalOpen(true);
  };

  const handleCancelReservation = (reservationId: string) => {
    setSelectedReservationId(reservationId);
    setOpenConfirmDialog(true);
  };

  function handleCloseConfirmDialog() {
    setOpenConfirmDialog(false);
  }

  const handleConfirmCancellation = () => {
    if (selectedReservationId) {
      cancelReservation({ id: selectedReservationId });
    }
  };

  const handleDateChange = (newDate: Dayjs) => {
    setSelectedDate(newDate);
    resetPage();
  };

  return (
    <Box>
      <Stack direction="row" spacing={2} justifyContent={"flex-end"} mb={2}>
        <Button
          variant="contained"
          onClick={() => handleCreateReservation()}
          sx={{
            color: theme.palette.neutral.contrastText,
            fontWeight: "bold",
          }}
        >
          Reservation
        </Button>
      </Stack>
      <Box
        sx={{
          position: "relative",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <FormControlLabel
          sx={{
            fontSize: "1rem",
          }}
          control={
            <Switch
              checked={includeWeekends}
              onChange={(e) => setIncludeWeekends(e.target.checked)}
            />
          }
          label="Show Weekend"
        />

        <WeekCalendar selectedDate={selectedDate} setSelectedDate={handleDateChange} />
      </Box>
      <Box>

        <MapSelect type={type} selectedMap={selectedMap} setSelectedMap={setSelectedMap} />

      </Box>
      <Box>
        {isLoading && <CircularProgress />}
        {isError && <div>Error loading reservations</div>}

        {!isLoading && data && (
          <>
            <ResourceTable
              data={data}
              page={page}
              rowsPerPage={rowsPerPage}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              handleCancelReservation={handleCancelReservation}
              handleCreateReservation={handleCreateReservation}
            />
          </>
        )}

        {createReservationModalOpen && (
          <CreateReservationDialog
            open={createReservationModalOpen}
            onClose={() => setCreateReservationModalOpen(false)}
            onSave={(details) => {
              setReservationDetails(details);
              refetch();
            }}
            initialDetails={reservationDetails}
            mapId={selectedMap?.id ?? ""}
          />
        )}

        <ConfirmationDialog
          open={openConfirmDialog}
          onClose={handleCloseConfirmDialog}
          onConfirm={handleConfirmCancellation}
          title={CONFIRMATION_TEXTS.cancelReservation.title}
          contentText={CONFIRMATION_TEXTS.cancelReservation.contentText}
          isLoading={cancelReservationLoading}
        />
      </Box>
    </Box>
  );
};

export default ResourceReservationTable;

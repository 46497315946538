import { useMutation } from "@tanstack/react-query";
import { deleteSpot } from "../../services/resources/resource.service";
import { useAlertStore } from "components/common/alert/useAlertStore";

const useSpotDelete = ({ onSucess }: { onSucess?: () => void }) => {
  const alertDispatch = useAlertStore((state) => state.dispatch);

  const { mutate, isPending, error } = useMutation({
    mutationFn: async ({ id }: { id: string }) => await deleteSpot({ id }),

    onSuccess: (data) => {
      alertDispatch({
        type: "ALERT/SET_SUCCESS",
        payload: {
          successMessage: data.message ?? "",
        },
      });
      onSucess?.();
    },
    onError: (err: any) => {
      alertDispatch({
        type: "ALERT/SET_ERROR",
        payload: {
          errorMessage: error.message ?? "",
        },
      });
    },
  });

  return {
    deleteSpot: mutate,
    deleteSpotLoading: isPending,
    deleteSpotError: error,
  };
};

export default useSpotDelete;

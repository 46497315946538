import React, { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { Typography, Box } from "@mui/material";
import UserProfilePicture from "../userProfilePicture";
import { getTenantAccount } from "services/authorization/authConfig";

interface UserProfileProps {
  userId?: string;
  className?: string;
}

const UserProfile: React.FC<UserProfileProps> = ({ userId = "", className = "" }) => {
  const { instance, accounts } = useMsal();
  const [userName, setUserName] = useState<string>("");
  const tenantAccount = getTenantAccount(accounts);

  useEffect(() => {
    const fetchUserName = async () => {

      if (!tenantAccount)
        return;

      const request = {
        scopes: ["User.Read"],
        account: tenantAccount,
      };

      try {
        const response = await instance.acquireTokenSilent(request);

        const userResponse = await fetch("https://graph.microsoft.com/v1.0/me", {
          headers: {
            Authorization: `Bearer ${response.accessToken}`,
          },
        });
        const userData = await userResponse.json();
        setUserName(userData.displayName);
      } catch (error) {
      }
    };

    fetchUserName();
  }, [instance, accounts]);

  return (
    <Box display="flex" alignItems="center" className={className}>
      <Typography sx={{ mr: ".5rem", fontWeight: "bold" }} variant="body1">{userName}</Typography>
      <UserProfilePicture userId={userId} />
    </Box>
  );
};

export default UserProfile;

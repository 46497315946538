import { useQuery } from "@tanstack/react-query";
import { ReservationMapOverview } from "../../services/reservation/reservation.types";
import { getReservationMapOverview } from "../../services/reservation/reservation.service";

export const useReservationMapOverview = (mapId: string, date: string) => {
  return useQuery<ReservationMapOverview.Response>({
    queryKey: ["reservationMapOverview", mapId, date],
    queryFn: () => {
      return getReservationMapOverview({ mapId, date })
    },
    enabled: !!mapId && !!date
  });
};

import React from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { useFormikContext } from "formik";
import { TextFieldProps } from "@mui/material";

interface ReservationDatePickerProps {
  name: string;
}

const InputDatePicker: React.FC<ReservationDatePickerProps> = ({
  name,
}) => {
  const { values, setFieldValue, errors, touched } = useFormikContext<any>();

  return (
    <DatePicker
      label="Date"
      value={values[name] ? dayjs(values[name]) : null}
      onChange={(newValue) =>
        setFieldValue(name, newValue ? newValue.format("YYYY-MM-DD") : "")
      }
      slotProps={{
        textField: {
          fullWidth: true,
          margin: "normal",
          error: !!errors[name] && touched[name],
          helperText: touched[name] && errors[name],
        } as TextFieldProps,
      }}
    />
  );
};

export default InputDatePicker;

import React, { useState } from 'react';
import { FormikProvider, useFormik } from 'formik';
import { Box, Typography } from '@mui/material';
import FloorSelect from './floorSelect';
import FloorPlanUploader from './floorPlanUploader';

const FloorPlan: React.FC = () => {
  const [imageUrl, setImageUrl] = useState<string | undefined>("");
  const [floorId, setFloorId] = useState<string | null>(null);

  const formik = useFormik({
    initialValues: {
      type: "",
      mapId: "",
      imageUrl: "",
    },
    validate: (values) => {
      const errors: { type?: string } = {};
      if (!values.type) {
        errors.type = "Type is required";
      }
      return errors;
    },
    onSubmit: () => {},
  });

  const resetSelects = () => {
    formik.resetForm();
    setImageUrl(undefined);
    setFloorId(null);
  };

  return (
    <FormikProvider value={formik}>
      <Box sx={{ paddingLeft: 0, marginBottom: 3, marginTop: 3 }}>
        <Typography variant="h5" gutterBottom>
          Floor plans
        </Typography>
        <Typography variant="body1" gutterBottom>
          Upload floor plans for each of the buildings you have offices in.
        </Typography>
      </Box>
      <FloorSelect onImageChange={setImageUrl} onFloorIdChange={setFloorId} />
      <FloorPlanUploader
        initialImageUrl={imageUrl}
        floorId={floorId}
        onResetSelects={resetSelects}
      />
    </FormikProvider>
  );
};

export default FloorPlan;

import { Typography } from "@mui/material";
import SpotsMapper from "../mapper";
import {
  ResourceType,
} from "services/resources/defaultMap";
import dayjs from "dayjs";

interface editResourceProps {
  type: ResourceType;
}

export const EditResourcePage: React.FC<editResourceProps> = ({ type }) => {
  return (
    <>
      <Typography
        variant="h5"
        component="h1"
        sx={{ mt: 3, color: "secondary.main" }}
        gutterBottom
      >
        Create or delete spots
      </Typography>
      <Typography variant="body1" color="textSecondary" sx={{ mb: 4 }}>
        Manage your map by adding or removing spots as needed. Click to create a
        new spot, or delete existing ones with ease.
      </Typography>

      <SpotsMapper type={type} enableEditing={true} selectedDate={dayjs()} />
    </>
  );
};
export default EditResourcePage;

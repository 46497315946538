import { useMutation } from "@tanstack/react-query";
import { useAlertStore } from "components/common/alert/useAlertStore";
import { mapDelete } from "services/maps/maps.service";

const useMapDelete = ({
    onDelete,
    setErrors,
}: {
    onDelete: (values: any) => void;
    setErrors: (errors: any) => void;
}) => {
  const alertDispatch = useAlertStore((state) => state.dispatch);

  const { mutate, isPending, error } = useMutation({
    mutationFn: async ({ id }: { id: string }) =>
      await mapDelete({ id }),
      onSuccess: (data) => {
        onDelete(data);
        alertDispatch({
          type: "ALERT/SET_SUCCESS",
          payload: {
            successMessage: data.message ?? "",
          },
        });
      },
    onError: (err: any) => {
        const errors = err.errors || [];
        const formikErrors: any = {};
        if (err.message) {
          alertDispatch({
            type: "ALERT/SET_ERROR",
            payload: {
              errorMessage: err.message,
            },
          });
        }
      },
    });
  return {
    mutate, 
    isPending, 
    error 
};
};

export default useMapDelete;

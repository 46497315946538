import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Paper,
  useMediaQuery,
} from '@mui/material';
import { TimeslotsOverview } from '../../../services/timeslots/timeslots.types';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTheme } from '@mui/material/styles';
import CustomPagination from 'components/common/tablePagination';

interface ResourceTableProps {
  data: TimeslotsOverview.Timeslot[];
  totalCount: number;
  page: number;
  rowsPerPage: number;
  handleChangePage: (event: unknown, newPage: number) => void;
  handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onRowClick: (timeslot: TimeslotsOverview.Timeslot) => void;
}

const TimeslotsTable: React.FC<ResourceTableProps> = ({
  data,
  totalCount,
  page,
  rowsPerPage,
  handleChangePage,
  handleChangeRowsPerPage,
  onRowClick,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <TableContainer component={Paper} sx={{ mt: 3 }}>
      <Table aria-label='timeslots table'>
        {!isMobile && (
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>From</TableCell>
              <TableCell>To</TableCell>
              <TableCell>Default</TableCell>
            </TableRow>
          </TableHead>
        )}
        <TableBody>
          {data.map((row) => (
            <React.Fragment key={row.id}>
              {!isMobile ? (
                <TableRow
                  onClick={() => onRowClick(row)}
                  style={{ cursor: 'pointer' }}
                >
                  <TableCell sx={{ padding: '1rem' }}>
                    {row.name}
                  </TableCell>
                  <TableCell sx={{ padding: '1rem' }}>
                    {row.from}
                  </TableCell>
                  <TableCell sx={{ padding: '1rem' }}>
                    {row.to}
                  </TableCell>
                  <TableCell sx={{ padding: '1rem' }}>
                    {row.isDefault ? 'Yes' : 'No'}
                  </TableCell>
                </TableRow>
              ) : (
                <TableRow>
                  <TableCell colSpan={4}>
                    <Accordion
                      sx={{ boxShadow: 'none', border: 'none' }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`panel-content-${row.id}`}
                        id={`panel-header-${row.id}`}
                      >
                        <Typography sx={{fontWeight: 'bold'}}>{row.name}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Table>
                          <TableBody>
                            <TableRow>
                              <TableCell align='left'>From</TableCell>
                              <TableCell align='right'>{row.from}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell align='left'>To</TableCell>
                              <TableCell align='right'>{row.to}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell align='left'>Default</TableCell>
                              <TableCell align='right'>
                                {row.isDefault ? 'Yes' : 'No'}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </AccordionDetails>
                    </Accordion>
                  </TableCell>
                </TableRow>
              )}
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
      {totalCount > 0 && (
        <CustomPagination
          totalCount={totalCount}
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </TableContainer>
  );
};

export default TimeslotsTable;

import React, { useEffect, useState } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  CircularProgress,
} from "@mui/material";
import { useFormikContext } from "formik";
import { getErrorText } from "helpers";
import { useTimeslotListForReservation } from "hooks/reservation/useTimeslotListForReservation";

const TimeSlotAvailableSelect: React.FC = () => {
  const { values, handleChange, handleBlur, errors, touched, setFieldValue } = useFormikContext<any>();

  const { data, isError, isLoading, isSuccess } = useTimeslotListForReservation(values.resourceId, values.date);

  const [timeslot, setTimeslot] = useState('');

  useEffect(() => {
    if (data) {
      const defaultTimeslot = data?.timeslots.find(slot => slot.isDefault);
      if (defaultTimeslot && defaultTimeslot.isAvailable) {
        setFieldValue("timeslot", defaultTimeslot.id);
        setTimeslot(defaultTimeslot.id);
      }
    }
  }, [data]);

  if (!values.resourceId || !values.date)
    return null;

  if (isSuccess && !data?.timeslots?.length) {
    return null;
  }

  if (isLoading)
    return <CircularProgress />;

  return (
    <FormControl
      fullWidth
      margin="normal"
      variant="outlined"
      error={Boolean(errors.id && touched.id)}
    >
      <InputLabel shrink={false} htmlFor="timeslot">
        {values.timeslot ? "" : `Timeslot`}
      </InputLabel>
      <Select
        id="timeslot"
        name="timeslot"
        value={timeslot}
        onChange={handleChange}
        onBlur={handleBlur}
        displayEmpty
        disabled={!values.date || !values.resourceId}
      >
        <MenuItem value="" disabled>
          {`Timeslot`}
        </MenuItem>
        {isError && <MenuItem disabled>Error loading timeslots</MenuItem>}
        {data?.timeslots?.map((timeslot) => (
          <MenuItem key={timeslot.id} value={timeslot.id} disabled={!timeslot.isAvailable} selected={timeslot.isDefault}>
            {timeslot.name} - ({timeslot.from} - {timeslot.to})
          </MenuItem>
        ))}
      </Select>
      {touched.id && errors.id && (
        <FormHelperText sx={{ color: "red" }}>
          {getErrorText(errors.id)}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default TimeSlotAvailableSelect;

import { useMutation } from '@tanstack/react-query';
import { createReservation } from '../../services/reservation/reservation.service';
import { CreateReservation } from '../../services/reservation/reservation.types';
import { useAlertStore } from "components/common/alert/useAlertStore";

const useReservationCreate = ({
  onSave,
  onClose,
  setErrors,
}: {
  onSave: (values: any) => void;
  onClose: () => void;
  setErrors: (errors: any) => void;
}) => {
  const alertDispatch = useAlertStore((state) => state.dispatch);

  const { mutate, isPending, error } = useMutation({
    mutationFn: async ({ resourceId, date, timeslot }: CreateReservation.Request) =>
      await createReservation({ resourceId, date, timeslot }),
    onSuccess: (data, variables) => {
      onSave(variables);
      onClose();
      alertDispatch({
        type: "ALERT/SET_SUCCESS",
        payload: {
          successMessage: data.message ?? "",
        },
      });
    },
    onError: (err: any) => {
      const errors = err.errors || [];
      const formikErrors: any = {};
      if (err.message) {
        alertDispatch({
          type: "ALERT/SET_ERROR",
          payload: {
            errorMessage: err.message,
          },
        });
      }
      errors.forEach((apiError: any) => {
        formikErrors[apiError.field] = apiError.message;
      });
      setErrors(formikErrors);
    },
  });

  return {
    mutate,
    isPending,
    error,
  };
};

export default useReservationCreate;

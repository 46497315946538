import { create } from 'zustand';
import { redux } from 'zustand/middleware';



type FloorActionTypes = 'FLOOR/SET_FLOOR' | 'FLOOR/RESET_FLOOR';

type Floor = {
	id: string;
	name: string;
	type: string;
	url: string;
};

type FloorAction = {
	type: FloorActionTypes;
	payload?: Floor;
};

interface FloorState {
	floor: Floor | null;
}

const initialFloorState: FloorState = {
	floor: null,
};

function floorReducer(state: FloorState, action: FloorAction): FloorState {
	switch (action.type) {
		case 'FLOOR/SET_FLOOR':
			return {
				...state,
				floor: action.payload || null,
			};
		case 'FLOOR/RESET_FLOOR':
			return {
				...state,
				floor: null,
			};
		default:
			return state;
	}
}


export const useFloorStore = create(redux(floorReducer, initialFloorState));

export const setFloor = (floor: Floor): FloorAction => ({
	type: 'FLOOR/SET_FLOOR',
	payload: floor,
});

export const resetFloor = (): FloorAction => ({
	type: 'FLOOR/RESET_FLOOR',
});

import { useMutation } from "@tanstack/react-query";
import { useAlertStore } from "components/common/alert/useAlertStore";
import { CreateCheckInOut } from "services/check-in-out/check-in-out.types";
import { createCheckInOut } from "services/check-in-out/check-in-out.service";
import { queryClient } from "index";

const useCheckInOut = () => {
  const alertDispatch = useAlertStore((state) => state.dispatch);

  const { mutateAsync, isPending, error, data } = useMutation({
    mutationFn: async ({ type, mapId }: CreateCheckInOut.Request) => await createCheckInOut({ type, mapId }),
    onSuccess: (data) => {
      alertDispatch({
        type: "ALERT/SET_SUCCESS",
        payload: {
          successMessage: data.message || "",
        },
      });
      queryClient.setQueryData(['check-in-out'], data)
    },
    onError: (err: any) => {
      const errors = err.errors || [];
      const formikErrors: any = {};

      if (err.message) {
        alertDispatch({
          type: "ALERT/SET_ERROR",
          payload: {
            errorMessage: err.message,
          },
        });
      }

      errors.forEach((apiError: any) => {
        formikErrors[apiError.field] = apiError.message;
      });
    }
  });

  return {
    mutateAsync,
    isPending,
    error,
  };
};

export default useCheckInOut;

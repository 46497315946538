import { get, post } from "../httpHelper";
import { ResourceList, CreateSpot, DeleteSpot, EditSpot, QRCodeList } from "./resource.types";


export async function getResourceList({ mapId }: ResourceList.Request): Promise<ResourceList.Response> {
    const url = `Resource/list?MapId=${mapId}`;

    return await get(url);
};

export async function getQRCodeList({ mapId }: QRCodeList.Request): Promise<QRCodeList.Response> {
  const url = `Resource/qr-code?MapId=${mapId}`;

  return await get(url);
};

export async function createSpot({
    mapId,
    name,
    x,
    y,
    color
}: CreateSpot.Request): Promise<CreateSpot.Response> {
  const url = `Resource/create`;
  const payload = {
    mapId,
    name,
    x,
    y,
    color
  };

  return await post(url, payload);
}

export async function editSpot({
  id,
  name,
  color
}: EditSpot.Request): Promise<EditSpot.Response> {
const url = `Resource/update`;
const payload = {
  id,
  name,
  color
};

return await post(url, payload);
}


export async function deleteSpot({
    id
  }: DeleteSpot.Request): Promise<DeleteSpot.Response> {
    const url = `Resource/delete`;
    const payload = {
      id
    };
  
    return await post(url, payload);
  }
  

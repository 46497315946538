import { Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { IPublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { AuthenticatedAdminRoute, AuthenticatedRoute } from "./components/common/authorization";
import Login from "components/features/login";
import { scheduleSidebarItems, settingsSidebarItems } from "components/common/sideBar/sideBarItems";
import "@fontsource/noto-sans";
import { Box } from "@mui/material";
import Layout from "components/common/layout";
import { LoadingSpinner } from "components/common/loadingSpinner";
import { ResourceType } from "services/resources/defaultMap";
import { DefaultTab } from "./components/features/resourcePage";
import { EditResourcePage } from "./components/features/editResourcePage";
import FloorPlan from "components/features/floorPlan";
import Timeslots from "components/features/timeslots";
import CheckInOut from "components/features/check-in-out";

const People = lazy(() => import("./components/features/people"));
const ResourcePage = lazy(() => import("./components/features/resourcePage"));
const QRCodeList = lazy(() => import("./components/features/qr-code-list"));

type AppProps = {
  pca: IPublicClientApplication;
};

function App({ pca }: AppProps) {
  return (
    <MsalProvider instance={pca}>
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />

          <Route
            path="check-in-out/map/:mapId"
            element={<AuthenticatedRoute element={<Layout sidebarItems={scheduleSidebarItems} />} />}
            handle={{ crumb: () => <Link to="/check-in-out/map/:mapId">Checkin/Out</Link> }}
          >
            <Route
              path=""
              element={
                <Suspense fallback={<LoadingSpinner />}>
                  <CheckInOut />
                </Suspense>
              }
              handle={{ crumb: () => <Link to="/schedule/people">People</Link> }}
            />
          </Route>

          <Route
            path="schedule"
            element={<AuthenticatedRoute element={<Layout sidebarItems={scheduleSidebarItems} />} />}
            handle={{ crumb: () => <Link to="/schedule">Schedule</Link> }}
          >
            <Route
              path="people/map?/:map?/resouce?/:resourceId?"
              element={
                <Suspense fallback={<LoadingSpinner />}>
                  <People />
                </Suspense>
              }
              handle={{ crumb: () => <Link to="/schedule/people">People</Link> }}
            />
            <Route
              path="desks"
              element={
                <Suspense fallback={<LoadingSpinner />}>
                  <ResourcePage type={ResourceType.FLOOR} defaultTab={DefaultTab.MAP} />
                </Suspense>
              }
              handle={{ crumb: () => <Link to="/schedule/desks">Desks</Link> }}
            />
            <Route
              path="parking"
              element={
                <Suspense fallback={<LoadingSpinner />}>
                  <ResourcePage type={ResourceType.PARKING} defaultTab={DefaultTab.TABLE} />
                </Suspense>
              }
              handle={{ crumb: () => <Link to="/schedule/parking">Parking</Link> }}
            />
          </Route>

          <Route
            path="settings"
            element={
              <AuthenticatedAdminRoute
                element={<Layout sidebarItems={settingsSidebarItems} />}
              />

            }
            handle={{ crumb: () => <Link to="/settings">Settings</Link> }}
          >
            <Route
              path="floor-plan"
              element={
                <Suspense fallback={<LoadingSpinner />}>
                  <FloorPlan />
                </Suspense>
              }
              handle={{ crumb: () => <Link to="/settings/floor-plan">Floor Plan</Link> }}
            />
            <Route
              path="desks"
              element={
                <Suspense fallback={<LoadingSpinner />}>
                  <EditResourcePage type={ResourceType.FLOOR} />
                </Suspense>
              }
              handle={{ crumb: () => <Link to="/settings/desks">Desks</Link> }}
            />
            <Route
              path="parking"
              element={
                <Suspense fallback={<LoadingSpinner />}>
                  <EditResourcePage type={ResourceType.PARKING} />
                </Suspense>
              }
              handle={{ crumb: () => <Link to="/settings/parking">Parking</Link> }}
            />
            <Route
              path="timeslots"
              element={
                <Suspense fallback={<LoadingSpinner />}>
                  <Timeslots />
                </Suspense>
              }
              handle={{ crumb: () => <Link to="/settings/timeslots">Timeslots</Link> }}
            />
            <Route
              path="qr-codes/map/:mapId"
              element={
                <Suspense fallback={<LoadingSpinner />}>
                  <QRCodeList />
                </Suspense>
              }
              handle={{ crumb: () => <Link to="/settings/qr-codes">QR codes</Link> }}
            />
          </Route>
          <Route path="*" element={<Box>404 - Page Not Found</Box>} />
        </Routes>
      </Router>
    </MsalProvider>
  );
}

export default App;

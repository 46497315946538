import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  Switch,
  FormControlLabel,
  Box,
} from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { Formik, Form } from "formik";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import useTimeslotsCreate from "hooks/timeslots/useTimeslotsCreate";
import useTimeslotsUpdate from "hooks/timeslots/useTimeslotsUpdate";
import useTimeslotsDelete from "hooks/timeslots/useTimeslotsDelete";

interface TimeslotDialogProps {
  open: boolean;
  onClose: () => void;
  onSave: () => void;
  timeslotData?: {
    id: string;
    name: string;
    from: string;
    to: string;
    isDefault: boolean;
  } | null;
}

const TimeslotDialog: React.FC<TimeslotDialogProps> = ({
  open,
  onClose,
  onSave,
  timeslotData = null,
}) => {
  const isEditMode = Boolean(timeslotData);

  const { mutate: createTimeslot, isPending: isCreating } = useTimeslotsCreate({
    onSave,
    onClose,
  });

  const { mutate: updateTimeslot, isPending: isUpdating } = useTimeslotsUpdate({
    onSave,
  });

  const { mutate: deleteTimeslot, isPending: isDeleting } = useTimeslotsDelete({
    onDelete: onSave,
  });

  const initialValues = {
    id: timeslotData?.id || "",
    name: timeslotData?.name || "",
    from: timeslotData ? dayjs(timeslotData.from, "HH:mm") : null,
    to: timeslotData ? dayjs(timeslotData.to, "HH:mm") : null,
    isDefault: timeslotData?.isDefault || false,
  };

  const handleSubmit = (values: typeof initialValues, { setErrors }: any) => {
    const payload = {
      id: values.id,
      name: values.name,
      from: values.from ? values.from.format("HH:mm:ss") : null,
      to: values.to ? values.to.format("HH:mm:ss") : null,
      isDefault: values.isDefault,
    };

    const handleError = (err: any) => {
      const errors = err.errors || [];
      const formikErrors: Record<string, string> = {};
      errors.forEach((apiError: any) => {
        formikErrors[apiError.field] = apiError.message;
      });
      setErrors(formikErrors);
    };

    if (isEditMode && timeslotData?.id) {
      updateTimeslot({ ...payload }, { onError: handleError });
    } else {
      createTimeslot(payload, { onError: handleError });
    }
  };

  const handleDelete = () => {
    if (isEditMode && timeslotData?.id) {
      deleteTimeslot({ id: timeslotData.id });
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
          {({ values, errors, touched, handleChange, setFieldValue }) => (
            <Form>
              <DialogTitle>
                {isEditMode ? "Edit Timeslot" : "New Timeslot"}
              </DialogTitle>
              <DialogContent>
                <Box display="flex" gap={2} alignItems="center" mb={2}>
                  <TimePicker
                    label="From"
                    value={values.from}
                    onChange={(newValue) => setFieldValue("from", newValue)}
                    slotProps={{
                      textField: {
                        name: "fromTime",
                        error: Boolean(touched.from && errors.from),
                        helperText: touched.from && errors.from,
                        fullWidth: true,
                      },
                    }}
                    sx={{ marginTop: 1 }}
                  />
                  <TimePicker
                    label="To"
                    value={values.to}
                    onChange={(newValue) => setFieldValue("to", newValue)}
                    slotProps={{
                      textField: {
                        name: "to",
                        error: Boolean(touched.to && errors.to),
                        helperText: touched.to && errors.to,
                        fullWidth: true,
                      },
                    }}
                    sx={{ marginTop: 1 }}
                  />
                </Box>
                <TextField
                  label="Name"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={values.isDefault}
                      onChange={(e) =>
                        setFieldValue("isDefault", e.target.checked)
                      }
                      name="isDefault"
                    />
                  }
                  label="Default timeslot"
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={onClose} color="secondary">
                  Cancel
                </Button>
                {isEditMode ? (
                  <>
                    <Button
                      onClick={handleDelete}
                      color="error"
                      disabled={isDeleting}
                      variant="outlined"
                    >
                      {isDeleting ? "Deleting..." : "Delete"}
                    </Button>
                    <Button type="submit" color="secondary" variant="contained" disabled={isUpdating}>
                      {isUpdating ? "Updating..." : "Update"}
                    </Button>
                  </>
                ) : (
                  <Button type="submit" color="secondary" variant="contained" disabled={isCreating}>
                    {isCreating ? "Saving..." : "Save"}
                  </Button>
                )}
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </LocalizationProvider>
  );
};

export default TimeslotDialog;

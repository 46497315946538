import { useQuery } from "@tanstack/react-query";
import useCheckInOut from "hooks/check-in-out/useCheckInOut";
import { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { CreateCheckInOut } from "services/check-in-out/check-in-out.types";

const CheckInOut: React.FC = () => {
  const { mapId } = useParams<{ mapId: string }>();
  const { mutateAsync } = useCheckInOut();
  const hasRunEffect = useRef(false);

  useEffect(() => {
    if (!hasRunEffect.current) {
      handleMutation();
      hasRunEffect.current = true;
    }
  }, []);

  const handleMutation = async () => {
    await mutateAsync({
      type: 'Office',
      mapId: mapId || '',
    });
  };

  const { data } = useQuery<CreateCheckInOut.Response>({
    queryKey: ['check-in-out'],
    enabled: false
  });

  return (
    <>
      {data?.message}
    </>
  );
};

export default CheckInOut;

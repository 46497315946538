import { get, post } from "../httpHelper";
import {
  CancelReservation,
  CreateReservation,
  ReservationMapOverview,
  ReservationTableOverview,
  ReservationPeopleOverview,
  TimeslotListForReservation,
} from "./reservation.types";

export async function getReservationTableOverview({
  mapId,
  showWeekend,
  date,
  page, 
  pageSize     
}: {
  mapId: string;
  showWeekend: boolean;
  date: string;
  page: number;
  pageSize: number;
}): Promise<ReservationTableOverview.Response> {
  const url = `Reservation/overview?MapId=${mapId}&ShowWeekend=${showWeekend}&Date=${date}&Page=${page}&PageSize=${pageSize}`;

  return await get(url);
}

export async function getReservationMapOverview({
  mapId,
  date,
}: ReservationMapOverview.Request): Promise<ReservationMapOverview.Response> {
  const url = `Reservation/map-overview?MapId=${mapId}&Date=${date}`;

  return await get(url);
}
export async function getReservationPeopleOverview({
  mapId,
  showWeekend,
  date,
  page,        
  pageSize     
}: {
  mapId: string;
  showWeekend: boolean;
  date: string;
  page: number;
  pageSize: number;
}): Promise<ReservationPeopleOverview.Response> {
  const url = `Reservation/users-reservations?MapId=${mapId}&ShowWeekend=${showWeekend}&Date=${date}&Page=${page}&PageSize=${pageSize}`;

  return await get(url);
}

export async function createReservation({
  resourceId,
  date,
  timeslot
}: CreateReservation.Request): Promise<CreateReservation.Response> {
  const url = `Reservation/create`;
  const payload = {
    resourceId,
    date,
    timeslot
  };

  return await post(url, payload);
}

export async function cancelReservation({
  id,
}: CancelReservation.Request): Promise<CancelReservation.Response> {
  const url = `Reservation/cancel`;
  const payload = {
    id,
  };

  return await post(url, payload);
}

export async function getTimeslotListForReservation({
  resourceId,
  date,
}: TimeslotListForReservation.Request): Promise<TimeslotListForReservation.Response> {
  const url = `Reservation/time-slot-list-reservation?resourceId=${resourceId}&date=${date}`;
  return await get(url);
}
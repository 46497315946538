import React from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  CircularProgress,
} from "@mui/material";
import { useFormikContext } from "formik";
import { useResourceList } from "../../../hooks/resources/useResourceList";
import { getErrorText } from "helpers";

interface ReservationResourceSelectProps {
  mapId: string;
}

const ResourceSelect: React.FC<ReservationResourceSelectProps> = ({
  mapId,
}) => {
  const { values, handleChange, handleBlur, errors, touched } =
    useFormikContext<any>();
  const { data, isError, isLoading } = useResourceList(mapId);
  return (
    <FormControl
      fullWidth
      margin="normal"
      variant="outlined"
      error={Boolean(errors.resourceId && touched.resourceId)}
    >
      <InputLabel shrink={false} htmlFor="resourceId">
        {values.resourceId ? "" : `Place`}
      </InputLabel>
      <Select
        id="resourceId"
        name="resourceId"
        value={values.resourceId || ""}
        onChange={handleChange}
        onBlur={handleBlur}
        displayEmpty
      >
        <MenuItem value="" disabled>
          {`Place`}
        </MenuItem>
        {isLoading && <CircularProgress />}
        {isError && <MenuItem disabled>Error loading places</MenuItem>}
        {data?.resources?.map((resource) => (
          <MenuItem key={resource.id} value={resource.id}>
            {resource.name}
          </MenuItem>
        ))}
      </Select>
      {touched.resourceId && errors.resourceId && (
        <FormHelperText sx={{ color: "red" }}>
          {getErrorText(errors.resourceId)}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default ResourceSelect;

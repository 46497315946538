import { useMutation } from "@tanstack/react-query";
import { useAlertStore } from "components/common/alert/useAlertStore";
import { timeslotsDelete } from "services/timeslots/timeslots.service";
import { TimeslotsDelete } from "services/timeslots/timeslots.types";

const useTimeslotsDelete = ({
    onDelete,
}: {
    onDelete: (values: any) => void;
}) => {
  const alertDispatch = useAlertStore((state) => state.dispatch);

  const { mutate, isPending, error } = useMutation({
    mutationFn: async ({ id }: TimeslotsDelete.Request) =>
      await timeslotsDelete({ id }),
      onSuccess: (data) => {
        onDelete(data);
        alertDispatch({
          type: "ALERT/SET_SUCCESS",
          payload: {
            successMessage: data.message ?? "",
          },
        });
      },
    onError: (err: any) => {
        if (err.message) {
          alertDispatch({
            type: "ALERT/SET_ERROR",
            payload: {
              errorMessage: err.message,
            },
          });
        }
      },
    });
  return {
    mutate, 
    isPending, 
    error 
};
};

export default useTimeslotsDelete;

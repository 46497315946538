import { PaletteOptions } from "@mui/material/styles";

const palette: PaletteOptions = {
  primary: {
    main: "#4baed7",
  },
  secondary: {
    main: "#000516",
  },
  background: {
    default: "#f7f7f7",
    paper: "#ffffff",
  },
  neutral: {
    main: "#666666",
    light: "#b0b0b0",
    dark: "#333333",
    contrastText: "#ffffff",
  },
};

export default palette;

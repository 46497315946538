import { get, post } from "../httpHelper";
import { MapsList, MapImageUpload, MapCreate, MapUpdate, MapDelete } from "./maps.types";

export async function getMapsList({
  type,
}: MapsList.Request): Promise<MapsList.Response> {
  const url = `Maps/list?Type=${type}`;
  return await get(url);
}


export async function uploadMapImage({
  id,
  file,
}: MapImageUpload.Request): Promise<MapImageUpload.Response> {
  const url = `Maps/upload-image`;

  const formData = new FormData();
  formData.append("id", id);
  formData.append("file", file);

  return await post(url,"", formData, 'multipart/form-data');
}



export async function mapCreate({
  name,
  type,
}: MapCreate.Request): Promise<MapCreate.Response> {
  const url = `/Maps/create`;
  const payload = {
    name,
    type,
  };
  return await post(url, payload);
}


export async function mapUpdate({
  id,
  name,
}: MapUpdate.Request): Promise<MapUpdate.Response> {
  const url = `/Maps/update`;
  const payload = {
    id,
    name,
  };
  return await post(url, payload);
}


export async function mapDelete({
  id,
}: MapDelete.Request): Promise<MapDelete.Response> {
  const url = `/Maps/delete`;
  const payload = {
    id,
  };
  return await post(url, payload);
}
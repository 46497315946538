import { useMutation } from '@tanstack/react-query';
import { useAlertStore } from "components/common/alert/useAlertStore";
import { mapCreate } from 'services/maps/maps.service';
import { MapCreate } from 'services/maps/maps.types';

const useMapCreate = ({
  onSave,
  onClose,
  setErrors,
}: {
  onSave: (values: any,) => void;
  onClose: () => void;
  setErrors: (errors: any) => void;
}) => {
  const alertDispatch = useAlertStore((state) => state.dispatch);

  const { mutate, isPending, error } = useMutation({
    mutationFn: async ({ name, type }: MapCreate.Request) =>
      await mapCreate({ name, type }),
    onSuccess: (data, variables) => {
        const returnData = {
          id: data.id,
          name: variables.name,
          type: variables.type
        }
      onSave(returnData);
      onClose();
      alertDispatch({
        type: "ALERT/SET_SUCCESS",
        payload: {
          successMessage: data.message ?? "",
        },
      });
    },
    onError: (err: any) => {
      if (err.message) {
        alertDispatch({
          type: "ALERT/SET_ERROR",
          payload: {
            errorMessage: err.message,
          },
        });
      }         
    },
  });

  return {
    mutate,
    isPending,
    error,
  };
};

export default useMapCreate;

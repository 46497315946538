import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  IconButton,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  FormControl,
  Select,
  MenuItem,
} from '@mui/material';
import Map from '@mui/icons-material/Map';
import useMapCreate from 'hooks/maps/useMapCreate';
import { Form, Formik } from 'formik';
import { LoadingButton } from '@mui/lab';
import useMapImageUpload from 'hooks/maps/useMapImageUpload';
import { typeOptions } from 'helpers';
import theme from 'theme';
import { useFloorStore, resetFloor, setFloor } from './useFloorStore';


interface FloorPlanUploaderProps {
  initialImageUrl?: string;
  floorId?: string | null;
  onResetSelects: () => void;
}

const FloorPlanUploader: React.FC<FloorPlanUploaderProps> = ({
  initialImageUrl,
  floorId: externalFloorId,
  onResetSelects,
}) => {
  const [image, setImage] = useState<string | null>(initialImageUrl || null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [zoom, setZoom] = useState(1);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isFloorSaved, setIsFloorSaved] = useState(!!externalFloorId);
  const [floorData, setFloorData] = useState({ name: '', type: null });
  const [floorId, setFloorId] = useState<string | null>(externalFloorId || null);
  const [canUpload, setCanUpload] = useState(!!externalFloorId || !!initialImageUrl);
  const dispatch = useFloorStore((state) => state.dispatch);


  const { mutate: uploadImage, isUploading } = useMapImageUpload({
    onUploadSuccess: () => {
      if (selectedFile) {
        setImage(URL.createObjectURL(selectedFile));
      }
    },
  });

  const { mutate: createFloor, isPending: isSavingFloor } = useMapCreate({
    onSave: (values) => {
      setFloorId(values.id);
      setIsFloorSaved(true);
      setCanUpload(true);
      setIsDialogOpen(false);
      const newFloor = { id: values.id, name: values.name, type: values.type, url: values.url };
      dispatch(setFloor(newFloor));
      setImage(newFloor.url);
    },
    onClose: () => setIsDialogOpen(false),
    setErrors: () => {},
  });

  useEffect(() => {
    if (initialImageUrl) {
      setImage(initialImageUrl);
      setCanUpload(true);
    }
    if (externalFloorId) {
      setFloorId(externalFloorId);
      setIsFloorSaved(true);
      setCanUpload(true);
    }
  }, [initialImageUrl, externalFloorId]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0] || null;
    if (file && floorId) {
      setSelectedFile(file);
      const fileURL = URL.createObjectURL(file);
      setImage(fileURL);
      uploadImage({ id: floorId, file });
    }
  };

  const handleUploadClick = () => {
    if (canUpload) document.getElementById("floor-plan-upload-input")?.click();
  };

  const handleWheelZoom = (event: React.WheelEvent<HTMLDivElement>) => {
    setZoom((prevZoom) => {
      const newZoom = event.deltaY > 0 ? prevZoom - 0.1 : prevZoom + 0.1;
      return Math.min(Math.max(newZoom, 1), 3);
    });
  };

  const handleNewFloorClick = () => {
    resetDialogData()
    dispatch(resetFloor());
    setIsDialogOpen(true);
    onResetSelects();
  };

  const resetDialogData = () => {
    setFloorData({ name: "", type: null });
    setImage(null);
    setSelectedFile(null);
    setIsFloorSaved(false);
    setFloorId(null);
    setCanUpload(false);
  };

  return (
    <Box
      sx={{
        width: "100%",
        marginTop: 3,
        display: "flex",
        flexDirection: {
          xs: "column-reverse",
          md: "row",
        },
      }}
    >
      <Box
        sx={{
          backgroundColor: "#fff",
          borderRadius: "8px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: { xs: "100%", md: "85%" },
          height: "30rem",
          cursor: canUpload ? "pointer" : "not-allowed",
          position: "relative",
          overflow: "hidden",
        }}
        onClick={handleUploadClick}
      >
        {isUploading ? (
          <CircularProgress />
        ) : image ? (
          <Box
            component="img"
            src={image}
            alt="Floor Plan"
            sx={{
              transform: `scale(${zoom})`,
              transition: "transform 0.2s ease",
              width: "100%",
              height: "100%",
              objectFit: "contain",
              cursor: canUpload ? "zoom-in" : "not-allowed",
            }}
            onWheel={handleWheelZoom}
          />
        ) : (
          <Box textAlign="center">
            <IconButton color="primary" component="span">
              <Map fontSize="large" />
            </IconButton>
            <Typography variant="body1" color="textSecondary">
              {canUpload
                ? "Upload floor plan"
                : "Select or create a floor to upload an image"}
            </Typography>
          </Box>
        )}
        <input
          type="file"
          accept="image/*"
          id="floor-plan-upload-input"
          style={{ display: "none" }}
          onChange={handleFileChange}
        />
      </Box>

      <Box
        sx={{ marginLeft: { xs: 0, md: 2 }, marginBottom: { xs: 2, md: 0 } }}
      >
        <Button
          variant="contained"
          onClick={handleNewFloorClick}
          sx={{
            color: theme.palette.neutral.contrastText,
            fontWeight: "bold",
            minWidth: { xs: "100%", md: "120px" },
          }}
        >
          New Floor
        </Button>
      </Box>

      <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
        <Formik
          initialValues={{ name: "", type: null }}
          onSubmit={(values, { setErrors }) => {
            createFloor(
              { name: values.name, type: values.type },
              {
                onError: (err) => {
                  const errors = err.errors || [];
                  const formikErrors: any = {};
                  errors.forEach((apiError: any) => {
                    formikErrors[apiError.field] = apiError.message;
                  });
                  setErrors(formikErrors);
                },
              },
            );
          }}
        >
          {({ values, handleChange, errors, touched }) => (
            <Form>
              <DialogTitle>Create New Floor</DialogTitle>
              <DialogContent>
                <TextField
                  label="Floor Name"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                />
                <FormControl
                  fullWidth
                  margin="normal"
                  error={Boolean(touched.type && errors.type)}
                >
                  <Select
                    name="type"
                    value={values.type}
                    onChange={handleChange}
                    displayEmpty
                  >
                    <MenuItem value="" disabled>
                      Select Type
                    </MenuItem>
                    {typeOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                  {touched.type && errors.type && (
                    <Typography variant="caption" color="error">
                      {errors.type}
                    </Typography>
                  )}
                </FormControl>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    setIsDialogOpen(false);
                    resetDialogData();
                  }}
                  color="primary"
                >
                  Cancel
                </Button>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  color="secondary"
                  loading={isSavingFloor}
                  disabled={isSavingFloor}
                >
                  Save
                </LoadingButton>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </Box>
  );
};

export default FloorPlanUploader;



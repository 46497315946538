import { Box, IconButton, Typography } from "@mui/material";
import { DateCalendar, LocalizationProvider } from "@mui/x-date-pickers";
import { Dayjs } from "dayjs";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import React, { useState } from "react";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import theme from '../../../theme'
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

interface CalendarProps {
    selectedDate: Dayjs;
    setSelectedDate: (date: Dayjs) => void;
}

const Calendar: React.FC<CalendarProps> = ({
    selectedDate,
    setSelectedDate,
}) => {

    const [openCalendar, setOpenCalendar] = useState(false);

    const handleDateSelect = (date: Dayjs | null) => {
        if (date) {
            setSelectedDate(date);
        }
        setOpenCalendar(false);
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box
                sx={{
                    position: "relative",
                    display: "flex",
                }}>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                        flexGrow: 1,
                        justifyContent: "flex-end",
                    }}
                >
                    <IconButton onClick={() => setSelectedDate(selectedDate.subtract(1, "day"))}>
                        <ArrowBackIosIcon />
                    </IconButton>

                    <Typography
                        variant="body1"
                        gutterBottom
                        sx={{
                            color: theme.palette.secondary.main,
                            verticalAlign: "middle",
                            marginBottom: "0",
                            fontSize: "1rem"
                        }}
                    >
                        {selectedDate.format("ddd, MMMM DD")}
                    </Typography>

                    <IconButton onClick={() => setSelectedDate(selectedDate.add(1, "day"))}>
                        <ArrowForwardIosIcon />
                    </IconButton>

                    <IconButton onClick={() => setOpenCalendar(!openCalendar)}>
                        <CalendarTodayIcon sx={{ color: theme.palette.primary.main }} />
                    </IconButton>
                </Box>

                {openCalendar && (
                    <Box
                        sx={{
                            position: "absolute",
                            top: "40px",
                            right: 0,
                            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                            zIndex: 99,
                            bgcolor: theme.palette.neutral.contrastText
                        }}
                    >
                        <DateCalendar value={selectedDate} onChange={handleDateSelect} />
                    </Box>
                )}
            </Box>
        </LocalizationProvider>
    );
};

export default Calendar;

import { useMutation } from '@tanstack/react-query';
import { useAlertStore } from "components/common/alert/useAlertStore";
import { mapUpdate } from 'services/maps/maps.service';
import { MapUpdate } from 'services/maps/maps.types';


const useMapUpdate = ({
  onSave,
}: {
  onSave: (values: any) => void;
  setErrors: (errors: any) => void;
}) => {
  const alertDispatch = useAlertStore((state) => state.dispatch);

  const { mutate, isPending, error } = useMutation({
    mutationFn: async ({ id, name }: MapUpdate.Request) =>
      await mapUpdate({ id, name }),
    onSuccess: (data) => {
      onSave(data);
      alertDispatch({
        type: "ALERT/SET_SUCCESS",
        payload: {
          successMessage: data.message ?? "",
        },
      });
    },
    onError: (err: any) => {
      if (err.message) {
        alertDispatch({
          type: "ALERT/SET_ERROR",
          payload: {
            errorMessage: err.message,
          },
        });
      }
    },
  });

  return {
    mutate,
    isPending,
    error,
  };
};

export default useMapUpdate;
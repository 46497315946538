import { useQuery } from "@tanstack/react-query";
import { getTimeslotListForReservation } from "services/reservation/reservation.service";
import { TimeslotListForReservation } from "services/reservation/reservation.types";

export const useTimeslotListForReservation = (
  resourceId: string,
  date: string
) => {
  return useQuery<TimeslotListForReservation.Response>({
    queryKey: ["timeslotListForReservation", resourceId, date],
    queryFn: () => getTimeslotListForReservation({ resourceId, date }),
    enabled: !!resourceId && !!date
  });
};

import { get, post } from "../httpHelper";
import { TimeslotsCreate, TimeslotsDelete, TimeslotsOverview, TimeslotsUpdate } from "./timeslots.types";



export async function getTimeslotsOverview({ page, pageSize }: { page: number; pageSize: number; }): Promise<TimeslotsOverview.Response> {
  const url = `Timeslots?&Page=${page}&PageSize=${pageSize}`;
  return await get(url);
}


export async function timeslotsCreate({
  name,
  from,
  to,
  isDefault
}: TimeslotsCreate.Request): Promise<TimeslotsCreate.Response> {
  const url = `Timeslots/create`;
  const payload = {
    name,
    from,
    to,
    isDefault
  };
  return await post(url, payload);
}


export async function timeslotsUpdate({
  id,
  name,
  from,
  to,
  isDefault
}: TimeslotsUpdate.Request): Promise<TimeslotsUpdate.Response> {
  const url = `Timeslots/update`;
  const payload = {
    id,
    name,
    from,
    to,
    isDefault
  };
  return await post(url, payload);
}


export async function timeslotsDelete({
  id,
}: TimeslotsDelete.Request): Promise<TimeslotsDelete.Response> {
  const url = `Timeslots/delete`;
  const payload = {
    id,
  };
  return await post(url, payload);
}


export async function requireUseOfTimeslots(
  active: boolean
): Promise<{ message: string }> {
  const url = `Timeslots/requireUseOfTimeslots`;
  const payload = { active };
  return await post(url, payload);
}

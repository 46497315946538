import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { format, isToday, parseISO } from "date-fns";
import { ReservationTableOverview } from "services/reservation/reservation.types";
import { renderStatus } from "./renderStatus";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTheme } from "@mui/material/styles";
import CustomPagination from "components/common/tablePagination";

interface ResourceTableProps {
  data: ReservationTableOverview.Response;
  page: number;
  rowsPerPage: number;
  handleChangePage: (event: unknown, newPage: number) => void;
  handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleCreateReservation: (date: string | null, userId: string | null) => void;
  handleCancelReservation: (reservationId: string) => void,
}



const ResourceTable: React.FC<ResourceTableProps> = ({
  data,
  page,
  rowsPerPage,
  handleChangePage,
  handleChangeRowsPerPage,
  handleCreateReservation,
  handleCancelReservation,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const totalCount = data.total || 0;

  return (
    <TableContainer sx={{ mt: 3 }}>
      <Table>
        {!isMobile && (
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              {data.days.map((date) => {
                const dateObj = parseISO(date);
                const isTodayDate = isToday(dateObj);
                return (
                  <TableCell
                    key={date}
                    align="center"
                    sx={{
                      color: isTodayDate ? theme.palette.neutral.dark : "",
                      whiteSpace: "nowrap", 
                    }}
                  >
                    {format(dateObj, "EEE dd MMM")}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
        )}
        <TableBody>
          {data.resources.map((resource) => (
            <React.Fragment key={resource.resourceId}>
              <TableRow>
                {!isMobile && (
                  <TableCell component="th" scope="row">
                    {resource.resourceName}
                  </TableCell>
                )}
                {!isMobile &&
                  data.days.map((date) => {
                    const reservation = resource.days[date];

                    return (
                      <TableCell key={date} align="center">
                        {renderStatus(
                          reservation,
                          handleCancelReservation,
                          handleCreateReservation,
                          date,
                          resource.resourceId,
                        )}
                      </TableCell>
                    );
                  })}
                {isMobile && (
                  <TableCell colSpan={data.days.length}>
                    <Accordion
                      sx={{
                        boxShadow: "none",
                        border: "none",
                        width: "100%",
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`panel-content-${resource.resourceId}`}
                        id={`panel-header-${resource.resourceId}`}
                        sx={{ padding: "0 0 0 1rem", minHeight: "48px" }}
                      >
                        <Typography
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            padding: "0",
                            width: "100%",
                          }}
                        >
                          {resource.resourceName}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails sx={{ padding: "0 16px" }}>
                        <Table>
                          <TableBody>
                            <TableRow sx={{ marginBottom: "1rem" }}>
                              {data.days.map((date) => {
                                const reservation = resource.days[date];
                                return (
                                  <TableCell
                                    key={date}
                                    data-label={format(
                                      parseISO(date),
                                      "EEE dd MMM",
                                    )}
                                    sx={{
                                      whiteSpace: "nowrap",
                                      padding: "8px 4px",
                                    }}
                                  >
                                    {renderStatus(
                                      reservation,
                                      handleCancelReservation,
                                      handleCreateReservation,
                                      date,
                                      resource.resourceId,
                                    )}
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          </TableBody>
                        </Table>
                      </AccordionDetails>
                    </Accordion>
                  </TableCell>
                )}
              </TableRow>
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
      {totalCount > 0 && (
        <CustomPagination
          totalCount={totalCount}
          page={page}  
          rowsPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </TableContainer>
  );
};

export default ResourceTable;

import { useQuery } from "@tanstack/react-query";
import { ReservationTableOverview } from "../../services/reservation/reservation.types";
import { getReservationTableOverview } from "../../services/reservation/reservation.service";

export const useReservationTableOverview = (
  mapId: string,
  showWeekend: boolean,
  date: string,
  page: number,
  pageSize: number
) => {
  return useQuery<ReservationTableOverview.Response>({
    queryKey: ["reservationTableOverview", mapId, showWeekend, date, page, pageSize],
    queryFn: () => getReservationTableOverview({ mapId, showWeekend, date, page, pageSize }),
    enabled: !!mapId
  });
};

import { getImage, post } from "../httpHelper";
import { LoginUser } from "./user.types";

export const userLogin = async (user: LoginUser.Request): Promise<LoginUser.Response> => {
    const url = 'user/login';
    const data = {
        name: user.name,
        userId: user.userId,
        username: user.username,
    };
    return await post(url, data);
};

export async function getProfilePicture(token: string, userId: string): Promise<any> {
    const endpoint = userId
        ? `https://graph.microsoft.com/v1.0/users/${userId}/photo/$value`
        : 'https://graph.microsoft.com/v1.0/me/photo/$value';

    return await getImage(endpoint, token);
};
import { useQuery } from "@tanstack/react-query";
import { getTimeslotsOverview } from "services/timeslots/timeslots.service";
import { TimeslotsOverview } from "services/timeslots/timeslots.types";

export const useTimeslotsOverview = (
  page: number = 1,  
  pageSize: number = 10   
) => {
  return useQuery<TimeslotsOverview.Response>({
    queryKey: ["timeslotsOverview",  page, pageSize ],
    queryFn: () => getTimeslotsOverview({  page, pageSize }),
    enabled: !!page || !!pageSize
  });
};
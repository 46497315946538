import { Box } from "@mui/material";
import UserProfilePicture from "components/common/userProfilePicture";
import { ReservationTableOverview } from "services/reservation/reservation.types";
import theme from "theme";

export const renderStatus = (
  day: ReservationTableOverview.DayReservation | null,
  handleCancelReservation: (reservationId: string) => void,
  handleCreateReservation: (date: string | null, resourceId: string | null) => void,
  date: string,
  resourceId: string
) => {
  if (day === undefined) return <div>/</div>;

  const isClickable = day == null || (day && (day.isAvailable || day.canCancel));
  const cursor = isClickable ? "pointer" : "default";
  const color = theme.palette.neutral.light;

  const hover = {
    color: isClickable ? theme.palette.primary.main : color,
    fontWeight: isClickable ? "bold" : "",
    cursor: cursor,
  }

  return (
    <Box
      style={{
        cursor: cursor,
      }}
      sx={{
        display: "flex",
        alignItems: "center",
        color: color,
        "&:hover": hover,
        transition: "color 0.3s",
        paddingLeft: {
          xs: "45%",
          sm: "0",
        },
        paddingBottom: {
          xs: "1rem",
          sm: "0",
        },
      }}
      onClick={() => {
        if (day == null) {
          return handleCreateReservation(date, resourceId);
        } else if (day.canCancel && day.reservationId) {
          return handleCancelReservation(day.reservationId);
        } else {
          return undefined;
        }
      }}
    >
      <>
        {day && day.isReserved ? (
          <>
            <UserProfilePicture userId={day.userId} className="hideOnMobile" />
            <Box sx={{ textAlign: "left", color: theme.palette.neutral.dark }}>{day.reservedBy}</Box>
          </>
        ) : (
          "Available"
        )}
      </>
    </Box >
  );
};
import { useMutation } from '@tanstack/react-query';
import { requireUseOfTimeslots } from 'services/timeslots/timeslots.service';

const useRequireUseOfTimeslots = () => {
  const { mutate, isPending } = useMutation({
    mutationFn: async (active: boolean) => await requireUseOfTimeslots(active),
  });

  return {
    mutate,
    isPending,
  };
};

export default useRequireUseOfTimeslots;

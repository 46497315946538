import { useMutation } from '@tanstack/react-query';
import { useAlertStore } from "components/common/alert/useAlertStore";
import { timeslotsCreate } from 'services/timeslots/timeslots.service';
import { TimeslotsCreate } from 'services/timeslots/timeslots.types';

const useTimeslotsCreate = ({
  onSave,
  onClose,
}: {
  onSave: (values: any,) => void;
  onClose: () => void;
}) => {
  const alertDispatch = useAlertStore((state) => state.dispatch);

  const { mutate, isPending, error } = useMutation({
    mutationFn: async ({ name, from, to, isDefault }: TimeslotsCreate.Request) => await timeslotsCreate({ name, from, to, isDefault }),
    onSuccess: (data, variables) => {
      const returnData = {
        id: data.id,
        name: variables.name,
        from: variables.from,
        to: variables.to,
        isDefault: variables.isDefault
      }
      onSave(returnData);
      onClose();
      alertDispatch({
        type: "ALERT/SET_SUCCESS",
        payload: {
          successMessage: data.message ?? "",
        },
      });
    },
    onError: (err: any) => {
      if (err.message) {
        alertDispatch({
          type: "ALERT/SET_ERROR",
          payload: {
            errorMessage: err.message,
          },
        });
      }
    },
  });

  return {
    mutate,
    isPending,
    error,
  };
};

export default useTimeslotsCreate;

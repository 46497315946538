import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Typography,
} from "@mui/material";
import { ChromePicker } from "react-color";
import { LoadingButton } from "@mui/lab";
import { Formik, Form } from "formik";
import useCreateSpot from "../../../hooks/resources/useResourceCreate";
import useEditSpot from "../../../hooks/resources/useResourceEdit";

interface CreateSpotDialogProps {
  open: boolean;
  spotData: {
    mapId?: string;
    name: string;
    x?: number;
    y?: number;
    color: string;
    id?: string;
  };
  isExistingSpot: boolean;
  onClose: () => void;
  onSave: () => void;
  onDelete: () => void;
}

const CreateSpotDialog: React.FC<CreateSpotDialogProps> = ({
  open,
  spotData,
  isExistingSpot,
  onClose,
  onSave,
  onDelete,
}) => {
  const { mutateEdit, isPendingEdit } = useEditSpot({
    onSave,
    onClose,
    setErrors: () => {},
  });

  const { mutateCreate, isPendingCreate } = useCreateSpot({
    onSave,
    onClose,
    setErrors: () => {},
  });

  return (
    <Dialog open={open} onClose={onClose}>
      <Formik
        initialValues={spotData}
        enableReinitialize
        onSubmit={(values, { setErrors }) => {
          if(isExistingSpot){
            mutateEdit(
              {
                id: values.id ?? "",
                name: values.name,
                color: values.color,
              },
              {
                onError: (err: { errors: any; }) => setErrors(err.errors || {}),
              },
            );
          }
          else{
            mutateCreate(
              {
                mapId: values.mapId ?? "",
                name: values.name,
                x: values.x ?? 0,
                y: values.y ?? 0,
                color: values.color,
              },
              {
                onError: (err: { errors: any; }) => setErrors(err.errors || {}),
              },
            );
          }
         
        }}
      >
        {({ values, handleChange, setFieldValue, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <DialogTitle>{isExistingSpot? "Edit" : "Add new"} spot</DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                label="Spot Name"
                name="name"
                type="text"
                fullWidth
                value={values.name}
                onChange={handleChange}
              />
              <Typography variant="body1" style={{ marginTop: 16 }}>
                Select Spot Color:
              </Typography>
              <ChromePicker
                color={values.color}
                onChangeComplete={(color) => setFieldValue("color", color.hex)}
                disableAlpha
              />
            </DialogContent>
            <DialogActions>
              {isExistingSpot && (
                <Button onClick={onDelete} color="error" variant="outlined">
                  Delete
                </Button>
              )}
              <Button onClick={onClose}>Cancel</Button>
              <LoadingButton
                type="submit"
                variant="contained"
                color="secondary"
                loading={isExistingSpot ? isPendingEdit : isPendingCreate}
                disabled={isExistingSpot ? isPendingEdit : isPendingCreate}
              >
                Save Spot
              </LoadingButton>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default CreateSpotDialog;
